html{
    background-color: #F5F5F5;
    /* height: 100vh; */
}

.MainWindow{
    /* padding: 16px; */
    
    background-color: #F5F5F5;
}

.MainContent{
    padding: 16px;
    /* height: 100vh; */
}

.inputcalendar{
    /* display: inline; */
    /* width: 0; */
    border: 0;
    width: 0;
    background-color: transparent;

}

.calendarioIcon button{
    padding: 0;
    margin: 0;
    margin-left: -4px;
}

.indicador{
    border: 1px solid;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    font-size: small;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color:rgb(25, 118, 210);
    
}

.indicador div:only-child{
    font-size: large;
    
}

.warning{
    border-color: rgb(199, 199, 13);
}


