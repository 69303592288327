/* True White: #F5F6F2 */
/* Accent #1976d2 */

.appBarIcon{
    background-color: transparent;
    border-radius: 100%;
    border-color: #1976d2;
    border-style: solid;
    border-width: 2px;
    padding: 2px;
    
}

header div{
    background-color: #FFF;
    color: #1976d2;
}

header{
    padding-bottom: 0px;
}