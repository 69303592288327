table{
    width: 100%;
    text-align: center;
    border: 1px solid rgb(185, 201, 214);
}

.deleteRow{
    text-align: center;
    border-left: 1px solid rgb(185, 201, 214);
    
}



th{
    padding: 10px;
    border-bottom: 1px solid rgb(185, 201, 214);
    color: rgb(87, 94, 101);
    margin-bottom: 5px;
    margin-top: 10px;
}

.btn-new{
    text-align: right;
}